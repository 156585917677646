//
// user.scss
// Use this to write your custom SCSS
//


// NAVBAR STYLING

// The logo is normally a dark color. Inverts logo to light color when dark mode is enabled.
[data-bs-theme="dark"] .ix-navbar-logo-color {
  filter: invert(100%);
}

// CONSULTING PAGE

// Limit the width of the image so that it looks good even on wider screens.
@include media-breakpoint-up(lg) {
  .ix-about-hero-text-width {
    max-width: 530px;
  }
}

// Add rounding to the top right corner to make it look better.
@include media-breakpoint-up(md) {
  .ix-hero-rounded-tr {
    border-top-right-radius: 25px;
  }
}

// ONBOARDING PAGE

// Change color of "steps" numbering circles so that they are different than the section background.
[data-bs-theme="dark"] .steps:not([data-bs-theme="light"]) {
  --si-steps-number-bg: #23262f;
  --si-steps-number-inner-bg: rgba(255, 255, 255, .04);
}
